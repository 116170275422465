import React from "react"

const HomestayFAQAccordion = () => {
  return (
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button
              class="btn btn-link"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              什麼是家庭寄宿？
            </button>
          </h5>
        </div>

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="card-body">
            他們是由 Study Links
            精心挑選出來的家庭，負責照顧您的孩子，確保他們放學後有一個放鬆和安全的生活環境。孩子們會非常有興趣瞭解家庭寄宿並且學習他們的文化，他們可以在這裏結交新朋友，講英語同時瞭解英國的生活方式。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              寄宿家庭是監護人嗎？
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <div class="card-body">
            不，Study Links 仍然是監護人，我們會安排您的孩子住在寄宿家庭，Study
            Links 將與他們保持聯繫以確保一切順利。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Study Links 將如何爲我選擇寄宿家庭？
            </button>
          </h5>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div class="card-body">
            當您註冊 Study Links
            時，我們會要求您發送有關您孩子和任何寄宿家庭偏好的一些詳細信息。我們會仔細研究可以提供接待服務的家庭，看看哪一個家庭最合適。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFour">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              學生能一直住在同一個家庭嗎？
            </button>
          </h5>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordion"
        >
          <div class="card-body">
            只要有可能，我們會盡量安排學生住在同一個家庭，只要他們在那裏住的開心。有時，當您的孩子需要住宿時，這個寄宿家庭卻無法提供寄宿服務，因此我們可能需要在這時候將他們安置在另一個合適的家庭中。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFive">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              我們什麼時候需要預訂寄宿家庭？
            </button>
          </h5>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordion"
        >
          <div class="card-body">
            您越早讓我們知道您的孩子需要寄宿家庭，他們就越有可能找到合適的寄宿家庭。如果他們全年都需要寄宿家庭，您可以提前通知我們，以便提前預訂。否則，我們需要在學期第一週前知道他們需要家庭寄宿。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSix">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              我的孩子可以在週末和假期住在其他地方嗎？
            </button>
          </h5>
        </div>
        <div
          id="collapseSix"
          class="collapse"
          aria-labelledby="headingSix"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
              Study Links
              寄宿家庭是您孩子在校外的最佳住宿選擇，因爲我們已經仔細覈查過這些寄宿家庭並且知道它們是安全和舒適的。{" "}
            </div>
            <br />
            <div>
              如果他們希望另外安排，我們需要得到您的書面許可，並提供負責人的詳細信息。Study
              Links 仍會在緊急情況下采取行動。
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSeven">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              寄宿家庭會提供什麼？
            </button>
          </h5>
        </div>
        <div
          id="collapseSeven"
          class="collapse"
          aria-labelledby="headingSeven"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
              我們的寄宿家庭各不相同，但至少會爲他們提供一個乾淨、舒適的居住環境、一日三餐和屬於自己的書房。{" "}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingEight">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              家庭寄宿取消截止日期/費用是多少？
            </button>
          </h5>
        </div>
        <div
          id="collapseEight"
          class="collapse"
          aria-labelledby="headingEight"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>這取決於您選擇的服務。 請參閱您的合同協議。</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingNine">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              家庭寄宿的費用是多少？
            </button>
          </h5>
        </div>
        <div
          id="collapseNine"
          class="collapse"
          aria-labelledby="headingNine"
          data-parent="#accordion"
        >
          <div class="card-body">
            這取決於您選擇的位置、家庭寄宿服務和監護服務。 請參閱您的合同協議。
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomestayFAQAccordion
